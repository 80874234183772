import Image from "next/image";
import styled, { css, keyframes } from "styled-components";
import { Theme } from "styles/theme";

const pendingAnimation = keyframes`
  50% {
    opacity: 0.1;
  }
`;

type DeliveryType = {
  delivered: boolean;
};

export const Wrapper = styled.div<DeliveryType>`
  ${({ delivered }) => css`
    padding: 3.4rem 0;
    opacity: ${delivered ? 0.5 : 1};
  `}
`;

export const OrderList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, auto));
  gap: 1.6rem;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fit, 41.2rem);
  }
`;

export const OrderItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  background-color: #ffffff;
  border-radius: 2.4rem;
  box-shadow: 1px 2px 7px 0 rgba(67, 61, 61, 0.09);
  cursor: pointer;
`;

export const OrderMerchantWrapper = styled.div`
  display: flex;
  gap: 2rem;
  border-bottom: solid 1px ${Theme.colors.wildSand};
  padding-bottom: 1.6rem;
`;
export const OrderMerchantData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.7rem;
`;
export const OrderMerchantImage = styled(Image)<DeliveryType>`
  ${({ delivered }) => css`
    width: 82px;
    max-height: 110px;
    object-fit: scale-down;

    ${delivered &&
    css`
      min-width: 7.2rem;
      height: 9.2rem;
    `}
  `}
`;
export const OrderMerchantName = styled.h2`
  font-family: "Nunito";
  font-size: 1.6rem;
  line-height: 1.13;
  font-weight: 600;
  color: ${Theme.colors.tundora};
`;
export const OrderMerchantTime = styled.p`
  font-family: "Quicksand";
  font-size: 1.2rem;
  color: ${Theme.colors.gray};
`;
export const WrapperStatus = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.7rem;
`;
export const StatusName = styled.h4`
  font-family: "Quicksand";
  font-size: 14px;
  font-weight: 600;
  color: #43cb8f;
`;
export const Status = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-top: 0.8rem;
`;
type StatusBarProps = {
  completed?: boolean;
  pending?: boolean;
};
export const StatusBar = styled.div<StatusBarProps>`
  ${({ completed = false, pending = false }) => css`
    width: 4.2rem;
    height: 1.2rem;
    border-radius: 0.4rem;
    background-color: ${Theme.colors.silver};

    ${completed &&
    css`
      background-color: #43cb8f;
    `}

    ${pending &&
    css`
      background-color: #43cb8f;
      opacity: 0.5;
      animation: ${pendingAnimation} 1s linear infinite;
    `}

    @media (min-width: 1024px) {
      width: 5.3rem;
    }
  `}
`;

export const OrderItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 0;
  border-bottom: solid 1px ${Theme.colors.wildSand};
  flex: 1;
`;
export const OrderItemsItem = styled.div``;
export const OrderItemsData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-family: "Quicksand";
    font-size: 1.6rem;
    color: ${Theme.colors.gray};
  }
`;

export const OrderTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 0 1rem;
`;
export const OrderTotalText = styled.div`
  font-family: "Nunito";
  font-size: 2rem;
  font-weight: bold;
  color: ${Theme.colors.tundora};
`;

export const LoadingShape = styled.div`
  background-color: white;
  border-radius: 24px;
  padding: 20px;
  margin: 8px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
