import {
  Tab as ReactTab,
  TabList as ReactTabList,
  TabPanel as ReactTabPanel,
  Tabs as ReactTabs,
} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import * as S from "./styles";

export type TabsProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

const Tabs = ({ children, ...rest }: TabsProps) => (
  <S.Wrapper {...rest}>
    <ReactTabs>{children}</ReactTabs>
  </S.Wrapper>
);

export const TabList = ReactTabList;
export const Tab = ReactTab;
export const TabPanel = ReactTabPanel;

export default Tabs;
