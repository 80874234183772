import * as Sentry from "@sentry/browser";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useQuery } from "@tanstack/react-query";

import { useAuth } from "contexts/AuthUserContext";
import { useAnalytics } from "hooks/useAnalytics";
import useCart from "hooks/useCart";
import { getOrdersEventStream } from "components/Orders";
import Avatar from "components/Avatar";

import * as S from "./styles";

const HeaderMenu = () => {
  const [cartNumber, setCartNumber] = useState(0);
  const { logout, authUser, isLoadingAuthUser } = useAuth();
  const { profile, isProfileLoading, getProfile } = useCart();
  const { toggleOpen, cart } = useCart();
  const { logBeginCheckout } = useAnalytics();
  const router = useRouter();

  const profileName =
    profile?.name && profile?.name.indexOf(" ") !== -1
      ? profile?.name.split(" ")[0]
      : profile?.name;

  const { data: orders, isPending: isLoading } = useQuery({
    enabled: !!authUser,
    queryKey: ["orders"],
    queryFn: getOrdersEventStream,
    retry: 1,
  });

  const countOnGoingOrders =
    orders?.filter(
      (order) =>
        order.lastStatus === "created" ||
        order.lastStatus === "in_progress" ||
        order.lastStatus === "ready" ||
        order.lastStatus === "in_transit"
    ).length || 0;

  useEffect(() => {
    if (authUser) {
      Sentry.addBreadcrumb({
        category: "auth",
        message: "Auth User Changed in getOrders" + authUser.uid,
        level: "info",
      });

      getProfile();
    }
  }, [isLoadingAuthUser]);

  useEffect(() => {
    setCartNumber(cart.items?.length);
  }, [cart]);

  return (
    <S.MenuList>
      <S.CartIcon
        onClick={() =>
          authUser
            ? router.push("/orders")
            : router.push("/login?return=/orders")
        }
      >
        {authUser && countOnGoingOrders > 0 && (
          <S.CartNumber className="cart-number cart-bounce">
            {countOnGoingOrders}
          </S.CartNumber>
        )}

        <img width={32} height={32} src="/img/icons-finances-order.svg"></img>
      </S.CartIcon>

      <li>
        <Avatar
          image={authUser?.photoUrl || "/img/icons-performance-takeout.svg"}
          onClick={() => {
            if (!authUser) return router.push("/login");
            router.push("/profile");
          }}
          size={32}
          style={{ marginRight: "4px" }}
        />
        {authUser && (
          <S.UserBox>
            <S.Name>
              {isProfileLoading && (
                <ContentLoader
                  speed={2}
                  width={50}
                  height={18}
                  viewBox="0 0 50 18"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="4" ry="4" width="50" height="18" />
                </ContentLoader>
              )}
            </S.Name>
            <S.Name>{!isProfileLoading && profileName}</S.Name>
            <S.LogoutButton onClick={logout}>Sign out</S.LogoutButton>
          </S.UserBox>
        )}
      </li>
      <S.CartIcon
        role="button"
        onClick={() => {
          toggleOpen();
          const cartValue = Number.isNaN(cart.subTotal)
            ? 0
            : cart.subTotal / 100;
          logBeginCheckout(cartValue);
        }}
      >
        {cartNumber > 0 && (
          <S.CartNumber className="cart-number cart-bounce" key={cartNumber}>
            {cartNumber}
          </S.CartNumber>
        )}

        <img width={32} height={32} src="/img/icons-performance-cart.svg" />
      </S.CartIcon>
    </S.MenuList>
  );
};

export default HeaderMenu;
