import * as S from "./styles";

const Footer = () => (
  <S.Wrapper>
    <S.Footer>
      <S.FooterList>
        <S.FooterListTitle>About Bento</S.FooterListTitle>
        <S.FooterListItem>
          <a href="mailto:hello@bento.ky">Say Hi 🙂</a>
        </S.FooterListItem>
        <S.FooterListItem>
          <a href="https://hello.bento.ky/careers">Careers</a>
        </S.FooterListItem>
      </S.FooterList>
      <S.FooterList>
        <S.FooterListTitle>Discover</S.FooterListTitle>
        <S.FooterListItem>
          <a href="mailto:sales@bento.ky">Register your store</a>
        </S.FooterListItem>
        <S.FooterListItem>
          <a href="https://hello.bento.ky/careers">Become a driver</a>
        </S.FooterListItem>
      </S.FooterList>
      <S.SocialList>
        <S.FooterListTitle>Social</S.FooterListTitle>
        <S.SociaWrapper>
          <S.FooterListItem>
            <a href="https://www.facebook.com/ky.bento" target="_blank">
              <img
                width={32}
                height={32}
                alt="Bento's Facebook Page"
                src="/img/ic-facebook.svg"
              ></img>
            </a>
          </S.FooterListItem>
          <S.FooterListItem>
            <a href="https://www.instagram.com/bento.ky/" target="_blank">
              <img
                width={32}
                height={32}
                alt="Bento's Instagram Page"
                src="/img/ic-instagram.svg"
              ></img>
            </a>
          </S.FooterListItem>
        </S.SociaWrapper>
        <S.DownloadButtons>
          <a
            href="https://apps.apple.com/us/app/bento-food/id1502809014"
            target="_blank"
          >
            <img
              alt="Download the Bento in App store"
              width={119}
              height={40}
              src="/img/ic-appstore.svg"
            ></img>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=ky.bento.app"
            target="_blank"
          >
            <img
              alt="Download the Bento in Google Play"
              width={135}
              height={40}
              src="/img/ic-googleplay.svg"
            ></img>
          </a>
        </S.DownloadButtons>
      </S.SocialList>
    </S.Footer>
    <S.Base>
      <S.AddressWrapper>
        <img width={60} height={60} src="/img/logo-footer.svg"></img>
        <S.Address>
          © Copyright {new Date().getFullYear()} - Bento - All rights reserved
          Bento
          <br />
          Grand Cayman, Cayman Islands.
        </S.Address>
      </S.AddressWrapper>
      <S.BaseLink href="https://hello.bento.ky/policies">
        Terms and conditions
      </S.BaseLink>
      <S.BaseLink href="https://hello.bento.ky/privacy-policy" target="_blank">
        Privacy Policy
      </S.BaseLink>
    </S.Base>
  </S.Wrapper>
);

export default Footer;
