import styled from "styled-components";
import { Theme } from "styles/theme";
import { FooterMenuItemLinkStyleProps } from "./interface";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  height: 56px;
  width: 100%;
  background-color: white;
  box-shadow: 1px 2px 7px 0 rgb(67 61 61 / 9%);
  z-index: 8000;

  display: block;
  @media (min-width: 768px) {
    display: none;
  }

  @media print {
    display: none;
  }
`;

export const FooterMenuList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
`;

export const FooterMenuItem = styled.li`
  min-width: 90px;
  height: 56px;
  margin: 0 0;
  flex-basis: 1;
`;

export const FooterMenuItemLink = styled.a<FooterMenuItemLinkStyleProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 56px;
  cursor: pointer;
  border-bottom: 3px solid white;
  padding-top: 5px;
  gap: 1px;

  :hover {
    border-bottom: 3px solid ${Theme.colors.sunsetOrange};
  }
`;

export const FooterMenuItemImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const FooterMenuItemText = styled.div`
  font-size: 10px;
`;
