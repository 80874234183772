import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.footer`
  min-height: 337px;
  width: 100%;
  background-color: ${Theme.colors.tundora};
  color: #ffffff;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const Footer = styled.div`
  max-width: 1272px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

export const FooterList = styled.ul`
  padding: 45px 0 0 0;
  list-style: none;
  font-size: 14px;
`;

export const FooterListTitle = styled.h4`
  margin-bottom: 16px;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 16px;
`;

export const FooterListItem = styled.li`
  margin-bottom: 16px;
  a {
    color: #ffffff;
    text-decoration: none;
    font-weight: normal;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const SocialList = styled.ul`
  width: 500px;
  padding: 45px 0 0 0;
  list-style: none;
  font-size: 14px;
`;

export const SociaWrapper = styled.div`
  display: flex;

  li {
    margin-right: 16px;
  }
`;

export const Base = styled.div`
  max-width: 1272px;
  margin: 20px auto;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px #686363;
`;

export const Address = styled.div`
  font-style: none;
  width: 520px;
`;

export const AddressWrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
  }
`;

export const BaseLink = styled.a`
  width: 306px;
  font-size: 14px;
  text-align: center;
  color: #fff;

  &&&:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:visited {
    text-decoration: none;
    color: #fff;
  }

  &:-webkit-any-link {
    text-decoration: none;
  }
`;

export const DownloadButtons = styled.div`
  display: flex;

  a {
    margin-right: 16px;
  }
`;
