import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.header`
  height: 70px;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  background-color: ${Theme.colors.sunsetOrange};

  @media (min-width: 768px) {
    display: none;
  }

  @media print {
    display: none;
  }
`;

export const InputWrapper = styled.div`
  padding: 24px 16px 0 16px;
  margin-bottom: -8px;
  background-color: white;

  @media (min-width: 768px) {
    display: none;
  }

  @media print {
    display: none;
  }
`;

export const Logo = styled.img`
  font-family: "Nunito";
  font-size: 38px;
  letter-spacing: -0.1rem;
  color: white;
  font-weight: 600;
  cursor: pointer;
`;
