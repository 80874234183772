import SearchInput from "components/SearchInput";
import { useRouter } from "next/router";
import React from "react";
import * as S from "./styles";

const HeaderMobile = () => {
  const router = useRouter();

  const shouldRenderSearchInput =
    router.pathname === "/" || router.pathname === "/merchants";

  const goHome = () => {
    router.push("/");
  };

  return (
    <>
      <S.Wrapper>
        <S.Logo src="/img/header-logo.svg" onClick={goHome} />
      </S.Wrapper>
      {shouldRenderSearchInput && (
        <S.InputWrapper>
          <SearchInput />
        </S.InputWrapper>
      )}
    </>
  );
};

export default HeaderMobile;
