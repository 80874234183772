import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.main`
  background-color: ${Theme.colors.wildSand};
`;

export const Container = styled.div`
  width: 36rem;
  height: 100%;
  margin: 0 auto;
  padding: 0;

  @media (min-width: 768px) {
    width: 740px;
  }

  @media (min-width: 1024px) {
    width: 960px;
  }

  @media (min-width: 1400px) {
    width: 1280px;
  }
`;

export const LoadingShape = styled.div`
  background-color: white;
  border-radius: 2.4rem;
  padding: 1.6rem;
  margin: 1.6rem;
  margin-left: 0.8rem;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const HeaderTitle = styled.div`
  font-size: 6.4rem;
  font-weight: bold;
  font-family: "Nunito";
  color: ${Theme.colors.tundora};
  background-color: white;
`;

export const BgWhite = styled.div`
  background-color: #ffffff;
`;

export const BgPage = styled.div`
  background-color: ${Theme.colors.wildSand};
  border-top-left-radius: 3.2rem;
  border-top-right-radius: 3.2rem;
  min-height: 40rem;
`;
