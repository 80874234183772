import { add, differenceInMinutes, format, getDay } from "date-fns";

export const formatDistanceToNow = (date: Date, withTime = true) => {
  const diff = differenceInMinutes(new Date(), date);

  if (diff <= 1) {
    return "Now";
  } else if (diff > 1 && diff <= 90) {
    return `${diff} min ago`;
  } else if (diff > 90) {
    return withTime
      ? format(date, "MMM dd, yyyy h:mmaaa")
      : format(date, "dd MMM");
  }
};

export const getAPIToday = () => {
  const dayNumber = getDay(
    new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "America/Cayman",
      })
    )
  );
  const sundayNumber = 7;

  if (dayNumber === 0) return sundayNumber;
  return dayNumber;
};
