import { useAuth } from "contexts/AuthUserContext";
import { useRouter } from "next/router";

import * as S from "./styles";

const FooterMenu = () => {
  const router = useRouter();
  const { authUser } = useAuth();
  const activeMap: { [key: string]: string } = {
    "/": "explore",
    "/merchants": "search",
    "/search": "search",
    "/orders": "orders",
    "/profile": "profile",
  };

  const activeItem = activeMap[router.pathname];

  return (
    <S.Wrapper>
      <S.FooterMenuList>
        <S.FooterMenuItem>
          <S.FooterMenuItemLink
            onClick={() => {
              router.push("/");
            }}
            active={activeItem === "explore"}
          >
            <S.FooterMenuItemImage src="/img/icons-explore.svg" />
            <S.FooterMenuItemText>Explore</S.FooterMenuItemText>
          </S.FooterMenuItemLink>
        </S.FooterMenuItem>
        <S.FooterMenuItem>
          <S.FooterMenuItemLink
            onClick={() => {
              router.push("/merchants");
            }}
            active={activeItem === "search"}
          >
            <S.FooterMenuItemImage src="/img/icons-search.svg" />
            <S.FooterMenuItemText>Search</S.FooterMenuItemText>
          </S.FooterMenuItemLink>
        </S.FooterMenuItem>
        <S.FooterMenuItem>
          <S.FooterMenuItemLink
            active={activeItem === "orders"}
            onClick={() =>
              authUser
                ? router.push("/orders")
                : router.push("/login?return=/orders")
            }
          >
            <S.FooterMenuItemImage src="/img/icons-orders.svg" />
            <S.FooterMenuItemText>Orders</S.FooterMenuItemText>
          </S.FooterMenuItemLink>
        </S.FooterMenuItem>
        <S.FooterMenuItem>
          <S.FooterMenuItemLink
            active={activeItem === "profile"}
            onClick={() =>
              authUser
                ? router.push("/profile")
                : router.push("/login?return=/profile")
            }
          >
            <S.FooterMenuItemImage src="/img/icons-profile.svg" />
            <S.FooterMenuItemText>Profile</S.FooterMenuItemText>
          </S.FooterMenuItemLink>
        </S.FooterMenuItem>
      </S.FooterMenuList>
    </S.Wrapper>
  );
};

export default FooterMenu;
