import HeaderMenu from "components/HeaderMenu";
import SearchInput from "components/SearchInput";
import { useRouter } from "next/router";
import React from "react";
import * as S from "./styles";

const Header = () => {
  const router = useRouter();
  const goHome = () => {
    router.push("/");
  };

  return (
    <S.Wrapper>
      <S.Logo onClick={goHome}>
        <img
          src="/img/bento-logo-header.svg"
          alt="Bento - All your favorite food in one place!"
          width={91}
          height={48}
        />
        <S.Slogan>All your favorite food in one place!</S.Slogan>
      </S.Logo>
      <SearchInput></SearchInput>
      <HeaderMenu></HeaderMenu>
    </S.Wrapper>
  );
};

export default Header;
