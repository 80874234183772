import {
  HighlightOff as CloseIcon,
  Search as SearchIcon,
} from "@styled-icons/material-outlined";
import Case from "case";
import { format, getDay } from "date-fns";
import { useRouter } from "next/router";
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useState,
} from "react";
import ContentLoader from "react-content-loader";
import { MdAccessTime } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import { useDebounce } from "react-use";

import Button from "components/Button";
import ItemDetail from "components/ItemDetail";
import { Modal } from "components/Modal";
import TextField from "components/TextField";
import { useAnalytics } from "hooks/useAnalytics";
import useModal from "hooks/useModal";
import api from "services/api";

import * as S from "./styles";
import MerchantAvailability from "components/MerchantsInfo/MerchantAvailability";

const MyLoader = () => (
  <S.LoaderContainer>
    <ContentLoader viewBox="0 0 228 80" height={80} foregroundColor={"#e6e6e6"}>
      <rect x="16" y="16" rx="0" ry="0" width="50" height="50" />

      <rect x="100" y="16" rx="4" ry="4" width="330" height="15" />
      <rect x="100" y="42" rx="4" ry="4" width="330" height="15" />
    </ContentLoader>
    <ContentLoader viewBox="0 0 228 80" height={80} foregroundColor={"#e6e6e6"}>
      <rect x="16" y="16" rx="0" ry="0" width="50" height="50" />

      <rect x="100" y="16" rx="4" ry="4" width="330" height="15" />
      <rect x="100" y="42" rx="4" ry="4" width="330" height="15" />
    </ContentLoader>
    <ContentLoader viewBox="0 0 228 80" height={80} foregroundColor={"#e6e6e6"}>
      <rect x="16" y="16" rx="0" ry="0" width="50" height="50" />

      <rect x="100" y="16" rx="4" ry="4" width="330" height="15" />
      <rect x="100" y="42" rx="4" ry="4" width="330" height="15" />
    </ContentLoader>
  </S.LoaderContainer>
);

const SearchInput = () => {
  const router = useRouter();
  const [term, setTerm] = useState("");
  const { logGlobalSearch } = useAnalytics();
  const [searchData, setSearchData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [openPreview, setOpenPreview] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [showItemDetail, setShowItemDetail] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal();
  const today = getDay(new Date());

  const closeItemDetail = () => {
    setModalIsOpen(false);
    const main = document.querySelector(".mainwrapper") as HTMLElement;
    if (main) main.style.overflow = "auto";
  };

  const openItemDetail = (item: any) => {
    setOpenPreview(false);
    item.price = 0;
    setCurrentItem(item);
    setModalIsOpen(true);
    setIsLoading(false);

    const main = document.querySelector(".mainwrapper") as HTMLElement;
    if (main) main.style.overflow = "hidden";
  };

  useEffect(() => {
    if (!isLoading) setShowItemDetail(true);
  }, [currentItem]);

  const renderItemDetail = (
    item: any,
    onClose: () => void,
    modalIsOpen: boolean
  ) => {
    return (
      <Modal widthAuto open={modalIsOpen} onClose={onClose}>
        <ItemDetail itemProp={item} close={onClose} find={true} />
      </Modal>
    );
  };

  const redirectToSearch = () => {
    const hasMinCharacters = term.length >= 2;
    if (!hasMinCharacters) {
      return;
    }

    router.push("/search/" + term);
  };

  const onSearch: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter") {
      logGlobalSearch(term);
      redirectToSearch();
    }
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setTerm(event.target.value);
  };

  useEffect(() => {}, [term]);

  const [, cancel] = useDebounce(
    () => {
      if (term.length >= 2) {
        getSearch();
        setOpenPreview(true);
      } else {
        setOpenPreview(false);
      }
    },
    400,
    [term]
  );

  const getSearch = async () => {
    setIsLoading(true);
    cancel();

    try {
      const { data } = await api.get(
        `/global-search?search=${term}&maxResults=10&page=1`
      );

      setSearchData(data);
      setSeeAll(data.items.length > 5 || data.merchants.length > 5);
    } finally {
      setIsLoading(false);
    }
  };

  const getAMPMHour = (time: string) => {
    const [hour, min] = time.split(":");
    return format(new Date().setHours(Number(hour), Number(min)), "hh:mm a");
  };

  const redirectToMerchant = (slug: any, id: any) => {
    setOpenPreview(false);
    location.href = `/merchants/${slug}/${id}`;
  };

  return (
    <S.SearchInputContainer>
      <TextField
        onKeyPress={onSearch}
        onChange={onChange}
        value={term}
        placeholder="Search by item or store"
        icon={
          term ? (
            <>
              <Button
                onClick={() => setTerm("")}
                icon={<CloseIcon />}
                style={{ padding: 0, height: "3.8rem" }}
                data-tip="Clear search"
                minimal
              />
              <ReactTooltip
                effect="solid"
                className="tooltip"
                backgroundColor="#433d3d"
                borderColor="#433d3d"
              />
            </>
          ) : (
            <SearchIcon />
          )
        }
        iconPosition="right"
      />
      {showItemDetail &&
        renderItemDetail(currentItem, closeItemDetail, modalIsOpen)}
      {openPreview && (
        <>
          <S.SearchOverlay onClick={() => setTerm("")} />
          <S.SearchPreviewContainer>
            <S.SearchPreviewWrapper>
              {!isLoading &&
                searchData &&
                searchData.items.length === 0 &&
                searchData.merchants.length === 0 && (
                  <S.Empty>No items found</S.Empty>
                )}

              {searchData && searchData.merchants.length > 0 && (
                <S.SearchPreviewTitle>Merchants</S.SearchPreviewTitle>
              )}
              {isLoading && <MyLoader />}

              {!isLoading &&
                searchData &&
                searchData.merchants.slice(0, 5).map((merchant: any) => {
                  const isClosed = !merchant.isOpen;
                  return (
                    <S.SearchPreviewItem
                      key={merchant.id}
                      onClick={() => {
                        redirectToMerchant(merchant.slug, merchant.id);
                      }}
                      isClosed={isClosed}
                    >
                      <S.SearchPreviewMerchantImage src={merchant.logo} />
                      <S.SearchPreviewItemTitle>
                        {Case.capital(Case.lower(merchant.name))}
                        <span>{merchant.category}</span>
                      </S.SearchPreviewItemTitle>
                      <div>
                        <S.SearchPreviewItemExtraInfo>
                          {merchant.isOnShift && !isClosed && (
                            <>
                              <MdAccessTime />
                              <div>
                                {merchant?.shifts.map((shift: any) => {
                                  const hasDay = shift.weekDays.some(
                                    (day: number) => day === today
                                  );
                                  return (
                                    hasDay &&
                                    shift.hours.map(
                                      (hour: any, index: number) => (
                                        <div key={`${hour.start}-${index}`}>
                                          {getAMPMHour(hour.start)} -{" "}
                                          {getAMPMHour(hour.end)}
                                        </div>
                                      )
                                    )
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </S.SearchPreviewItemExtraInfo>
                        <S.SearchPreviewItemStatus>
                          {!merchant.isOnShift && (
                            <MerchantAvailability
                              merchantDetails={merchant}
                            ></MerchantAvailability>
                          )}
                        </S.SearchPreviewItemStatus>
                      </div>
                    </S.SearchPreviewItem>
                  );
                })}
              {searchData && searchData.items.length > 0 && (
                <S.SearchPreviewTitle>Items</S.SearchPreviewTitle>
              )}
              {isLoading && <MyLoader />}

              {!isLoading &&
                searchData &&
                searchData.items.slice(0, 5).map((item: any) => (
                  <S.SearchPreviewItem
                    key={item.id}
                    onClick={() => {
                      openItemDetail(item);
                    }}
                  >
                    <S.SearchPreviewItemImage src={item.featuredPicture} />
                    <S.SearchPreviewItemTitle>
                      {Case.capital(Case.lower(item.name))}
                    </S.SearchPreviewItemTitle>
                    <S.SearchPreviewItemExtraItemInfo>
                      <S.MerchantName>{item.merchant_name}</S.MerchantName>
                      <S.ItemDescription>
                        {Case.capital(Case.lower(item.description))}
                      </S.ItemDescription>
                    </S.SearchPreviewItemExtraItemInfo>
                  </S.SearchPreviewItem>
                ))}
            </S.SearchPreviewWrapper>
            {seeAll && <S.SeeAll onClick={redirectToSearch}>See all</S.SeeAll>}
          </S.SearchPreviewContainer>
        </>
      )}
    </S.SearchInputContainer>
  );
};

export default SearchInput;
