import { AiOutlineCloudDownload } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";

import { useAnalytics } from "hooks/useAnalytics";

import * as S from "./styles";

export type SmartAppBannerProps = {
  os: "Android" | "iOS";
  onClose: () => void;
};

const SmartAppBanner = ({ os, onClose }: SmartAppBannerProps) => {
  const { logDownloadAppBanner } = useAnalytics();

  const title =
    os === "iOS"
      ? "Bento: Food Delivery in Cayman"
      : "Bento: Delivery Services and +";

  function setSmartAppBannerClosed() {
    localStorage.setItem("smartAppBannerClosed", JSON.stringify(true));
  }

  function handleClose() {
    setSmartAppBannerClosed();
    onClose();
  }

  function handleTapButton() {
    logDownloadAppBanner();
  }

  return (
    <S.Wrapper>
      <S.IconButton onClick={handleClose} minimal>
        <IoCloseOutline size={24} />
      </S.IconButton>
      <S.Logo>
        <img src="/img/logo-app.webp" />
      </S.Logo>
      <S.Box>
        <S.Title>{title}</S.Title>
        <S.Description>Cayman, your food is here.</S.Description>
      </S.Box>
      <S.IconButton
        as="a"
        href={
          os === "iOS"
            ? "https://apps.apple.com/us/app/bento-food-delivery-in-cayman/id1502809014"
            : "https://play.google.com/store/apps/details?id=ky.bento.app"
        }
        onClick={handleTapButton}
      >
        <AiOutlineCloudDownload size={24} />
      </S.IconButton>
    </S.Wrapper>
  );
};

export default SmartAppBanner;
