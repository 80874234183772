import Case from "case";
import { useRouter } from "next/router";

import Empty from "components/Empty";
import { Order } from "components/Orders/interface";
import { formatDistanceToNow } from "utils/formats/date";
import { formatCurrencyWithCode } from "utils/formats/number";
import { svgBlinking, toBase64 } from "utils/svgBlinking";
import { OrdersListProps } from "./interface";

import * as S from "./styles";

type OrderMerchantStatusProps = {
  status: string;
  type: string;
};

type OrderStatusType = {
  order: number;
  label: string;
};

const orderStatusMap = (type: string): { [key: string]: OrderStatusType } => {
  return {
    created: {
      order: 1,
      label: "Received",
    },
    in_progress: {
      order: 2,
      label: "Preparing",
    },
    ready: {
      order: type == "delivery" ? 2 : 3,
      label: type == "delivery" ? "Preparing" : "Ready!",
    },
    in_transit: {
      order: 3,
      label: "In transit",
    },
    picked: {
      order: 5,
      label: "Picked",
    },
    delivered: {
      order: 5,
      label: "Delivered",
    },
    completed: {
      order: 5,
      label: "Completed",
    },
    cancelled: {
      order: 0,
      label: "Cancelled",
    },
    rejected: {
      order: 0,
      label: "Cancelled",
    },
  };
};

const OrderMerchantStatus = ({ status, type }: OrderMerchantStatusProps) => {
  const orderStatus: OrderStatusType =
    orderStatusMap(type)[status as keyof typeof orderStatusMap];

  if (!orderStatus) return null;

  return (
    <S.WrapperStatus>
      <S.StatusName>{orderStatus.label}...</S.StatusName>
      <S.Status>
        <S.StatusBar
          completed={orderStatus.order > 1}
          pending={orderStatus.order === 1}
        />
        <S.StatusBar
          completed={orderStatus.order > 2}
          pending={orderStatus.order === 2}
        />
        <S.StatusBar
          completed={orderStatus.order > 3}
          pending={orderStatus.order === 3}
        />
        {type !== "takeout" && (
          <S.StatusBar
            completed={orderStatus.order > 4}
            pending={orderStatus.order === 4}
          />
        )}
      </S.Status>
    </S.WrapperStatus>
  );
};

const OrdersList = ({
  orders,
  orderStatus,
  delivered = false,
}: OrdersListProps) => {
  const router = useRouter();
  const hasOrders = !!orders.length;

  const goToOrderDetail = (order: Order) => {
    router.push("/orders/" + order.orderId);
  };

  return (
    <S.Wrapper delivered={delivered}>
      {hasOrders ? (
        <S.OrderList>
          {orders.map((order) => (
            <S.OrderItem
              key={order.orderId}
              onClick={() => goToOrderDetail(order)}
            >
              <S.OrderMerchantWrapper>
                <S.OrderMerchantImage
                  src={order.merchant?.logo || ""}
                  width="115"
                  height="145"
                  alt={order.merchant.name}
                  delivered={delivered}
                  placeholder="blur"
                  blurDataURL={`data:image/svg+xml;base64,${toBase64(
                    svgBlinking(115, 145)
                  )}`}
                />

                <S.OrderMerchantData>
                  <S.OrderMerchantName>
                    {Case.capital(Case.lower(order.merchant.name))} •{" "}
                    {order.shortId}
                  </S.OrderMerchantName>
                  <S.OrderMerchantTime>
                    {delivered ? (
                      formatDistanceToNow(new Date(order.updatedAt))
                    ) : (
                      <>
                        {formatDistanceToNow(new Date(order.createdAt))}
                        <br />
                        Last update:{" "}
                        {formatDistanceToNow(new Date(order.updatedAt))}
                      </>
                    )}
                  </S.OrderMerchantTime>

                  {!delivered && (
                    <OrderMerchantStatus
                      status={order.lastStatus}
                      type={order.type}
                    />
                  )}
                </S.OrderMerchantData>
              </S.OrderMerchantWrapper>

              <S.OrderItems>
                {order.items.map((item, idx) => (
                  <S.OrderItemsItem key={`${item.name}-${idx}`}>
                    <S.OrderItemsData>
                      <h3>
                        • {item.qty}x {Case.capital(Case.lower(item.name))}
                      </h3>
                    </S.OrderItemsData>
                  </S.OrderItemsItem>
                ))}
              </S.OrderItems>

              <S.OrderTotal>
                <S.OrderTotalText>Total</S.OrderTotalText>
                <S.OrderTotalText>
                  {formatCurrencyWithCode(
                    (order.total - order.totalDiscount) / 100
                  )}
                </S.OrderTotalText>
              </S.OrderTotal>
            </S.OrderItem>
          ))}
        </S.OrderList>
      ) : (
        <Empty
          image="/img/icons-message-empty-state.svg"
          description={`You don’t have any ${orderStatus} orders yet.`}
        />
      )}
    </S.Wrapper>
  );
};

export default OrdersList;
