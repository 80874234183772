import styled from "styled-components";
import { Theme } from "styles/theme";

export const CartIcon = styled.li`
  position: relative;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const MenuList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  justify-content: flex-end;

  li {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px;
  }

  ${CartIcon} {
    justify-content: center;
    cursor: pointer;
    margin-left: 28px;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }
`;

export const ButtonCart = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const UserBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 40px;
  align-items: flex-start;
`;

export const Name = styled.div`
  font-size: 14px;
`;

export const LogoutButton = styled.button`
  border: none;
  font-size: 10px;
  font-weight: bold;
  border-radius: 7px;
  background: #fff;
  color: ${Theme.colors.sunsetOrange};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const CartNumber = styled.span`
  position: absolute;
  top: 0;
  left: 25px;
  z-index: 2;
`;
