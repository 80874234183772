import { useRouter } from "next/router";
import { useEffect } from "react";
import ContentLoader from "react-content-loader";
import { Event, EventSourcePolyfill } from "event-source-polyfill";
import { useQuery, QueryKey } from "@tanstack/react-query";

import OrdersList from "components/OrdersList";
import Tabs, { Tab, TabList, TabPanel } from "components/Tabs";
import { useAuth } from "contexts/AuthUserContext";
import api from "services/api";
import { queryClient } from "lib/react-query";
import { Order } from "./interface";

import * as S from "./styles";

interface GetOrdersBody {
  queryKey: QueryKey;
}

const MyLoader = () => (
  <S.LoadingContainer>
    <S.LoadingShape>
      <ContentLoader
        viewBox="0 0 313 366"
        height={366}
        foregroundColor={"#e6e6e6"}
      >
        <rect x="0" y="0" rx="0" ry="0" width="115" height="145" />
        <rect x="130" y="8" rx="4" ry="4" width="230" height="20" />
        <rect x="130" y="50" rx="4" ry="4" width="230" height="10" />
        <rect x="130" y="70" rx="4" ry="4" width="230" height="10" />
        <rect x="130" y="100" rx="4" ry="4" width="80" height="10" />
        <rect x="130" y="120" rx="4" ry="4" width="230" height="10" />

        <rect x="0" y="180" rx="4" ry="4" width="330" height="15" />
        <rect x="0" y="210" rx="4" ry="4" width="330" height="15" />
        <rect x="0" y="240" rx="4" ry="4" width="280" height="15" />

        <rect x="0" y="340" rx="4" ry="4" width="100" height="20" />
        <rect x="230" y="340" rx="4" ry="4" width="100" height="20" />
      </ContentLoader>
    </S.LoadingShape>
    <S.LoadingShape>
      <ContentLoader
        viewBox="0 0 313 366"
        height={366}
        foregroundColor={"#e6e6e6"}
      >
        <rect x="0" y="0" rx="0" ry="0" width="115" height="145" />
        <rect x="130" y="8" rx="4" ry="4" width="230" height="20" />
        <rect x="130" y="50" rx="4" ry="4" width="230" height="10" />
        <rect x="130" y="70" rx="4" ry="4" width="230" height="10" />
        <rect x="130" y="100" rx="4" ry="4" width="80" height="10" />
        <rect x="130" y="120" rx="4" ry="4" width="230" height="10" />

        <rect x="0" y="180" rx="4" ry="4" width="330" height="15" />
        <rect x="0" y="210" rx="4" ry="4" width="330" height="15" />
        <rect x="0" y="240" rx="4" ry="4" width="280" height="15" />

        <rect x="0" y="340" rx="4" ry="4" width="100" height="20" />
        <rect x="230" y="340" rx="4" ry="4" width="100" height="20" />
      </ContentLoader>
    </S.LoadingShape>
    <S.LoadingShape>
      <ContentLoader
        viewBox="0 0 313 366"
        height={366}
        foregroundColor={"#e6e6e6"}
      >
        <rect x="0" y="0" rx="0" ry="0" width="115" height="145" />
        <rect x="130" y="8" rx="4" ry="4" width="230" height="20" />
        <rect x="130" y="50" rx="4" ry="4" width="230" height="10" />
        <rect x="130" y="70" rx="4" ry="4" width="230" height="10" />
        <rect x="130" y="100" rx="4" ry="4" width="80" height="10" />
        <rect x="130" y="120" rx="4" ry="4" width="230" height="10" />

        <rect x="0" y="180" rx="4" ry="4" width="330" height="15" />
        <rect x="0" y="210" rx="4" ry="4" width="330" height="15" />
        <rect x="0" y="240" rx="4" ry="4" width="280" height="15" />

        <rect x="0" y="340" rx="4" ry="4" width="100" height="20" />
        <rect x="230" y="340" rx="4" ry="4" width="100" height="20" />
      </ContentLoader>
    </S.LoadingShape>
  </S.LoadingContainer>
);

export function getOrdersEventStream({
  queryKey,
}: GetOrdersBody): Promise<Order[] | null> {
  return new Promise((resolve, reject) => {
    const eventSource = new EventSourcePolyfill(
      `${process.env.NEXT_PUBLIC_API_URL}/users/last-orders`,
      {
        withCredentials: true,
        headers: {
          Authorization: api.defaults.headers.Authorization as string,
        },
      }
    );
    eventSource.addEventListener("orders", (event: Event) => {
      const response = event as Event & { data: string };
      if (!response.data) {
        resolve(null);
        return;
      }
      const data = JSON.parse(response.data);
      queryClient.setQueryData(queryKey, data);
      resolve(data);
    });
    eventSource.addEventListener("error", (e) => {
      const errorEvent = e as Event & { error: Error };

      if (!errorEvent?.error?.message?.includes("Reconnecting"))
        eventSource.close();

      reject(e);
    });
  });
}

const Orders = () => {
  const { authUser, isLoadingAuthUser } = useAuth();
  const route = useRouter();

  const { data: orders, isPending: isLoading } = useQuery({
    enabled: !!authUser,
    queryKey: ["orders"],
    queryFn: getOrdersEventStream,
    retry: 1,
  });

  const onGoing = orders?.filter(
    (order) =>
      order.lastStatus === "created" ||
      order.lastStatus === "in_progress" ||
      order.lastStatus === "ready" ||
      order.lastStatus === "in_transit"
  );
  const delivered = orders?.filter(
    (order) =>
      order.lastStatus === "delivered" ||
      order.lastStatus === "picked" ||
      order.lastStatus === "completed"
  );
  const canceled = orders?.filter(
    (order) =>
      order.lastStatus === "cancelled" || order.lastStatus === "rejected"
  );

  useEffect(() => {
    if (!authUser && !isLoadingAuthUser) {
      route.push("/login?return=/orders");
    }
  }, [authUser, isLoadingAuthUser]);

  return (
    <S.Wrapper>
      <S.BgWhite>
        <S.Container>
          <S.HeaderTitle>Orders</S.HeaderTitle>
        </S.Container>
      </S.BgWhite>

      <Tabs>
        <S.BgWhite>
          <S.Container>
            <TabList>
              <Tab>Ongoing</Tab>
              <Tab>Completed</Tab>
              <Tab>Cancelled</Tab>
            </TabList>
          </S.Container>
        </S.BgWhite>

        <S.BgWhite>
          <S.BgPage>
            <S.Container>
              <TabPanel>
                {isLoading ? (
                  <MyLoader />
                ) : (
                  <OrdersList orders={onGoing || []} orderStatus="ongoing" />
                )}
              </TabPanel>
              <TabPanel>
                {isLoading ? (
                  <MyLoader />
                ) : (
                  <OrdersList
                    orders={delivered || []}
                    delivered
                    orderStatus="completed"
                  />
                )}
              </TabPanel>
              <TabPanel>
                {isLoading ? (
                  <MyLoader />
                ) : (
                  <OrdersList
                    orders={canceled || []}
                    delivered
                    orderStatus="cancelled"
                  />
                )}
              </TabPanel>
            </S.Container>
          </S.BgPage>
        </S.BgWhite>
      </Tabs>
    </S.Wrapper>
  );
};

export default Orders;
