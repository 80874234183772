import styled, { css } from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .react-tabs {
      &__tab-list {
        border-bottom: 0.1rem solid transparent;
        margin: 0;
        padding: 0;
      }

      &__tab {
        display: inline-block;
        border: none;
        border-bottom: 0.3rem solid transparent;
        bottom: -0.1rem;
        position: relative;
        list-style: none;
        font-family: "Nunito";
        font-weight: bold;
        font-size: 1.8rem;
        padding: 1.2rem 1.8rem;
        color: ${Theme.colors.tundora};
        cursor: pointer;
        transition: all 0.2s;

        @media (min-width: 480px) {
          font-size: 2rem;
          padding: 1.2rem 2.4rem;
        }

        &--selected {
          border-bottom-color: ${Theme.colors.sunsetOrange};
          border-bottom-width: 0.3rem;
          border-radius: 0.1rem;
          color: ${Theme.colors.sunsetOrange};
        }
      }
    }
  `}
`;
