import Button from "components/Button";
import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 2rem 1rem 0.4rem;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconButton = styled(Button)`
  padding: 0 0.8rem;
  color: ${Theme.colors.sunsetOrange};
`;

export const Logo = styled.div`
  padding: 0.8rem;
  border-radius: 1rem;
  overflow: hidden;
  margin-right: 1rem;
  background-color: ${Theme.colors.sunsetOrange};

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 5rem;
  }
`;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 1.5rem;
  margin-right: 1rem;
`;

export const Description = styled.p`
  font-family: "Nunito";
  font-size: 1.4rem;
  color: ${Theme.colors.gray};
`;
