import styled from "styled-components";

export const Wrapper = styled.header`
  width: 1270px;
  gap: 16px;
  margin: 32px auto;
  align-items: flex-end;
  justify-content: space-between;
  width: 360px;
  display: none;

  @media (min-width: 768px) {
    display: flex;
    width: 740px;
  }

  @media (min-width: 1024px) {
    width: 960px;
  }

  @media (min-width: 1400px) {
    width: 1280px;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
`;

export const Slogan = styled.div`
  font-size: 10px;
  margin-left: 16px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;
