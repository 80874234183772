import { Animation as LoadingAnimation } from "components/Loading/styles";
import { getContrast } from "polished";
import styled, { css } from "styled-components";
import { AvatarProps } from ".";
import { Theme } from "styles/theme";

type WrapperProps = Pick<AvatarProps, "size" | "color"> & { loading?: string };

export const Wrapper = styled.div<WrapperProps>`
  ${({ size, color, loading = "" }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${size}px;
    height: ${size}px;
    border-radius: 50%;
    background-color: ${color || Theme.colors.alto};
    color: ${Theme.colors.tundora};
    overflow: hidden;

    ${loading === "true" &&
    css`
      ${getContrast(color || Theme.colors.alto, Theme.colors.sunsetOrange) <
        1 &&
      css`
        ${LoadingAnimation} {
          border-top: 0.3rem solid rgba(255, 255, 255, 0.2);
          border-right: 0.3rem solid rgba(255, 255, 255, 0.2);
          border-bottom: 0.3rem solid rgba(255, 255, 255, 0.2);
          border-left: 0.3rem solid ${Theme.colors.tundora};
        }
      `};
    `}
  `}
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
`;

export const AvatarImage = styled.img`
  object-fit: scale-down;
`;
