import { InputWrapper } from "components/TextField/styles";
import styled, { css } from "styled-components";
import { Theme } from "styles/theme";

export const SearchInputContainer = styled.div`
  position: relative;
  width: 100%;

  .tooltip {
    padding: 0.3rem 0.6rem;
    border-radius: 0.8rem;
    font-family: "Quicksand";
    font-size: 1.2rem;
  }

  ${InputWrapper} {
    border-color: ${Theme.colors.wildSand};

    &:focus-within {
      border-color: ${Theme.colors.silver};
    }
  }

  @media (min-width: 768px) {
    display: block;
    width: 412px;
  }
`;

export const SearchPreviewContainer = styled.div`
  position: absolute;
  width: 100vw;
  max-height: 85vh;
  top: 43px;
  left: -16px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
  border-radius: 11px;
  padding: 16px 0 16px 0;
  background-color: #fff;
  z-index: 9888;

  @media (min-width: 768px) {
    max-height: initial;
    height: 85vh;
    width: 412px;
    height: auto;
    top: 43px;
    left: 0;
  }
`;

export const SearchPreviewWrapper = styled.div`
  width: 100vw;
  max-height: 76vh;
  overflow-y: auto;

  @media (min-width: 768px) {
    max-height: initial;
    height: 76vh;
    width: 412px;
    height: auto;
    overflow-y: auto;
  }
`;

export const SearchPreviewTitle = styled.div`
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 600;
  padding-left: 16px;
  color: ${Theme.colors.tundora};
`;

export const SearchPreviewItem = styled.div<{ isClosed?: boolean }>`
  ${({ isClosed = false }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px ${Theme.colors.wildSand};
    padding: 8px 16px;
    margin-bottom: 8px;
    cursor: pointer;
    opacity: ${isClosed ? 0.5 : 1};

    &:hover {
      background-color: ${Theme.colors.wildSand};
    }
  `}
`;

export const SearchPreviewItemImage = styled.img`
  width: 40px;
  object-fit: cover;
  margin-right: 16px;

  @media (min-width: 768px) {
    width: 60px;
  }
`;

export const SearchPreviewMerchantImage = styled.img`
  width: 40px;
  object-fit: contain;
  max-height: 40px;
  margin-right: 16px;

  @media (min-width: 768px) {
    width: 60px;
    max-height: 60px;
  }
`;

export const SearchPreviewItemTitle = styled.div`
  flex-grow: 4;
  font-family: "Nunito";
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  color: ${Theme.colors.tundora};
  line-height: 1.5;
  display: flex;
  flex-direction: column;

  span {
    display: block;
    font-family: "Quicksand";
    font-size: 12px;
    font-weight: normal;
    letter-spacing: normal;
    color: ${Theme.colors.gray};
  }
`;

export const SearchPreviewItemExtraInfo = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 4px;
  font-size: 12px;
  font-family: "Quicksand";
  text-align: right;
`;

export const SearchPreviewItemExtraItemInfo = styled.div`
  flex-grow: 1;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  gap: 4px;
  font-size: 12px;
  font-family: "Quicksand";
  text-align: right;
`;

export const SearchPreviewItemStatus = styled.div`
  text-align: right;
  color: #43cb8f;
  font-weight: 600;
  margin-top: 0.4rem;
  font-family: "Quicksand";
  font-size: 1.2rem;
`;

export const MerchantName = styled.div`
  color: #43cb8f;
  font-family: "Quicksand";
  font-size: 12px;
  font-weight: 600;
  text-align: right;
`;

export const ItemDescription = styled.div`
  font-family: "Quicksand";
  font-size: 12px;
  max-width: 150px;
  font-weight: normal;
  color: ${Theme.colors.gray};
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SeeAll = styled.div`
  font-family: Nunito;
  font-size: 12px;
  font-weight: 600;
  color: ${Theme.colors.sunsetOrange};
  text-align: center;
  cursor: pointer;
  padding: 10px 16px 0;
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
`;

export const Empty = styled.div`
  padding: 16px;
`;

export const SearchOverlay = styled.div`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9887;
`;
