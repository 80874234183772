import styled, { css } from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => css`
    position: fixed;
    bottom: 55px;
    height: 50px;
    width: 100%;
    box-shadow: 1px 2px 7px 0 rgb(67 61 61 / 9%);
    z-index: 8000;
    padding: 0 20px;
    color: #fff;
    background-color: ${Theme.colors.sunsetOrange};
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.2s ease-in-out;
    transform: translateY(${isVisible ? 0 : "50px"});
    cursor: pointer;

    @media (min-width: 768px) {
      bottom: 1.6rem;
      left: calc(50% - 15rem);
      width: 30rem;
      border-radius: 15rem;
      transform: translateY(${isVisible ? 0 : "70px"});
    }
  `}
`;

export const CartIcon = styled.div`
  position: relative;
`;

export const CartNumber = styled.div`
  position: absolute;
  top: 0;
  right: -5px;
  border-radius: 50%;
  background-color: #fff !important;
  color: ${Theme.colors.sunsetOrange} !important;
  font-weight: bold;
  z-index: 2;
`;

export const CompleteText = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
`;

export const TotalCart = styled.div`
  font-size: 1.6rem;
`;
