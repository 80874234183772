import { useEffect, useState } from "react";

import Footer from "components/Footer";
import FooterCart from "components/FooterCart";
import FooterMenu from "components/FooterMenu";
import Header from "components/Header";
import HeaderMobile from "components/HeaderMobile";
import SmartAppBanner from "components/SmartAppBanner";
import useCart from "hooks/useCart";
import { getMobileOS } from "utils/getMobileOS";

import { LayoutProps } from "./interface";
import * as S from "./styles";

const Layout = ({ children }: LayoutProps) => {
  const [os, setOs] = useState("Other");
  const [isClosed, setIsClosed] = useState(false);
  const { cart } = useCart();

  function getSmartAppBannerIsClosed(): boolean {
    const isClosed = localStorage.getItem("smartAppBannerClosed");
    return isClosed ? JSON.parse(isClosed) : false;
  }

  useEffect(() => {
    if (getMobileOS()) {
      setOs(getMobileOS());
      setIsClosed(getSmartAppBannerIsClosed());
    }
  }, []);

  return (
    <S.LayoutWrapper>
      {(os === "Android" || (os === "iOS" && !isClosed)) && (
        <SmartAppBanner os={os} onClose={() => setIsClosed(true)} />
      )}
      <HeaderMobile />
      <Header />
      {children}
      <Footer />
      {cart?.items?.length > 0 && <FooterCart />}
      <FooterMenu />
    </S.LayoutWrapper>
  );
};

export default Layout;
