import Loading from "components/Loading";

import * as S from "./styles";

export type AvatarProps = {
  text?: string;
  image?: string;
  color?: string;
  size?: number;
  loading?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
};

const Avatar = ({
  text,
  image,
  color,
  size = 40,
  loading = false,
  onClick,
  style,
}: AvatarProps) => (
  <S.Wrapper
    size={size}
    color={color}
    loading={String(loading)}
    style={style}
    onClick={onClick}
  >
    {loading ? (
      <Loading />
    ) : (
      <>
        {image && !image.startsWith("blob:") && (
          <S.AvatarImage
            src={image}
            width={`${size}`}
            height={`${size}`}
            alt="Avatar image"
          />
        )}
        {text && !image && <S.Text>{text}</S.Text>}
      </>
    )}
  </S.Wrapper>
);

export default Avatar;
